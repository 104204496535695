<template>
    <v-card style="position: relative; overflow: hidden !important;">
        <v-card-title class="light--text"
            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
            style="position: sticky; top: 0; width: 100%; z-index: 1000;"
        >
            <span ref="formTitle" class="headline">Nova alocação</span>

            <v-spacer></v-spacer>

            <v-btn 
                dark text class="mb-2 mr-4 pa-0"
                min-width="48px"
                @click="insertNewRow()"
                :disabled="loading"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn dark text class="mb-2 mr-4 pa-0"
                        v-on="on"
                        min-width="48px"
                        @click="deleteSelectedRow()"
                        :disabled="loading || disableDelete"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <span>Deletar linha</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn dark text class="mb-2 mr-4 pa-0"
                        v-on="on"
                        min-width="48px"
                        @click="save()"
                        :disabled="loading || disableSave"
                    >
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </template>
                <span>Salvar</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn dark text class="mb-2 mr-4 pa-0"
                        v-on="on"
                        min-width="48px"
                        @click="importSheet()"
                        :loading="importing"
                        :disabled="loading"
                    >
                        <v-icon>mdi-file-excel</v-icon>
                    </v-btn>
                    <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        @change="onFileChanged"
                    >
                </template>
                <span>Importar Excel</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn dark text class="mb-2 mr-4 pa-0"
                        v-on="on"
                        min-width="48px"
                        @click="exportSheet()"
                        :loading="exporting"
                        :disabled="loading"
                    >
                        <v-icon>mdi-microsoft-excel</v-icon>
                    </v-btn>
                </template>
                <span>Exportar Excel</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn dark text class="mb-2 pa-0"
                        v-on="on"
                        min-width="48px"
                        @click="closeNewEmployeeAllocationDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>Fechar</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text class="pa-0">
            <v-container fluid class="pa-0 ma-0">
                <v-row v-if="loading" class="my-4">
                    <v-col class="d-flex justify-center align-center">
                        <v-sheet class="ma-0 pa-0 d-flex justify-center align-center" :height="windowSize.y - 64 - 270">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                small
                            ></v-progress-circular>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-row v-else no-gutters>
                    <v-col class="pa-0">
                        <v-sheet class="rounded-b px-4 py-4">
                            <HotTable ref="hotTableNewEmployeeAllocation"
                                licenseKey="1c81c-153e1-cb283-a4838-af03f"
                                :data="filteredData"
                                :settings="settings"
                                :columns="filteredColumns"
                                :colHeaders="filteredColHeaders"
                                :rowHeaders="true"
                                :hiddenColumns="hiddenColumns"
                                :hiddenRows="hiddenRows"
                                :contextMenu="contextMenu"
                                :dropdownMenu="dropDownMenu"
                                :manualColumnResize="true"
                                :multiColumnSorting="true"
                                :bindRowsWithHeaders="true"
                                :filters="true"
                                :height="windowSize.y - 64 - 250"
                                width="100%"
                                :columnHeaderHeight="50"
                                :rowHeaderWidth="50"
                                :trimWhitespace="false"
                                :copyPaste="true"
                                stretchH="last"
                                :renderAllRows="false"
                            />
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import { HotTable } from '@handsontable/vue'
import { registerLanguageDictionary, ptBR } from 'handsontable/i18n'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import { HyperFormula } from 'hyperformula'
import moment from 'moment'
import lodash from 'lodash'

export default {
    name: 'ZnapNewEmployeeAllocationDialog',

    components: { HotTable },

    props: {
        newEmployeeAllocationDialog: { type: Boolean, required: true },
        objectData: { type: Array, required: true },
        columns: { type: Array, required: true },
        readOnlyColumns: { type: Array, required: true },
        colHeaders: { type: Array, required: true },
        event: { type: Object, required: true },
        windowSize: { type: Object, required: true },
        selectedRowDuplicate: { type: Number, required: false },
        costCenterItems: { type: Array, required: true },
        productItems: { type: Array, required: true },
        salesForceItems: { type: Array, required: true },
        salesForceBPItems: { type: Array, required: true },
        customerGroupItems: { type: Array, required: true },
        roleItems: { type: Array, required: true },
        statusItems: { type: Array, required: true },
        hrClassificationItems: { type: Array, required: true },
        selectedPlanningGroup: { type: Number, required: false, default: null },
    },

    data() {
        return {
            // Hot Table
            settings: {
                formulas: {
                    engine: HyperFormula,
                },
                afterSelection: () => {
                    if (this.hotInstance) {
                        this.selectedRows = this.hotInstance.getSelected()
                    }
                },
                afterChange: (changes) => {
                    if (changes) {
                        changes.forEach(c => {
                            let row = c[0]
                            let column = c[1]
                            let oldVal = c[2]
                            let newVal = c[3]
                            let physicalRow = this.hotInstance.toPhysicalRow(row)

                            if (oldVal !== newVal) {
                                if (column === 'employee_name') {
                                    if (newVal) {
                                        let foundEmployee = this.employeeRoles.find(e => e.text === newVal)

                                        this.selectedEmployeesItems.push({
                                            row: row,
                                            value: foundEmployee.id
                                        })

                                        this.filteredData[row].role = foundEmployee.role
                                        this.filteredData[row].id_role = foundEmployee.id_role
                                        this.filteredData[row].cd_employee = foundEmployee.cd_employee
                                        this.filteredData[row].id_employee = foundEmployee.id
                                        this.filteredData[row].hr_classification = foundEmployee.hr_classification
                                    } else {
                                        let index = this.selectedEmployeesItems.findIndex(e => e.row === row)
                                        if (index > -1) {
                                            this.selectedEmployeesItems.splice(index, 1)
                                        }

                                        this.filteredData[row].role = null
                                        this.filteredData[row].id_role = null
                                        this.filteredData[row].cd_employee = null
                                        this.filteredData[row].id_employee = null
                                        this.filteredData[row].hr_classification = null
                                    }
                                }

                                if (column === 'role') {
                                    if (newVal) {
                                        let foundRole = this.roleItems.find(r => r.text === newVal)

                                        this.selectedRoleItems.push({
                                            row: row,
                                            value: foundRole.id
                                        })

                                        this.filteredData[row].id_role = foundRole.id
                                    } else {
                                        let index = this.selectedRoleItems.findIndex(e => e.row === row)
                                        if (index > -1) {
                                            this.selectedRoleItems.splice(index, 1)
                                        }

                                        this.filteredData[row].id_role = null
                                    }
                                }

                                if (column === 'status') {
                                    if (newVal) {
                                        let foundStatus = this.statusItems.find(s => s.text === newVal)

                                        this.selectedStatusItems.push({
                                            row: row,
                                            value: foundStatus.id
                                        })

                                        this.filteredData[row].id_status = foundStatus.id
                                    } else {
                                        let index = this.selectedStatusItems.findIndex(e => e.row === row)
                                        if (index > -1) {
                                            this.selectedStatusItems.splice(index, 1)
                                        }

                                        this.filteredData[row].id_status = null
                                    }
                                }

                                if (column === 'hr_classification') {
                                    if (newVal) {
                                        let foundHrClassification = this.hrClassificationItems.find(h => h.text === newVal)

                                        this.selectedHrClassificationItems.push({
                                            row: row,
                                            value: foundHrClassification.id
                                        })

                                        this.filteredData[row].id_hr_classification = foundHrClassification.id
                                    } else {
                                        let index = this.selectedHrClassificationItems.findIndex(e => e.row === row)
                                        if (index > -1) {
                                            this.selectedHrClassificationItems.splice(index, 1)
                                        }

                                        this.filteredData[row].id_hr_classification = null
                                    }
                                }

                                if (column === 'product_name') {
                                    if (newVal) {
                                        let foundProduct = this.productItems.find(i => i.text === newVal)

                                        let foundCostCenter = this.costCenterItems.find(i => i.id_product === foundProduct.id)

                                        let foundSalesForce = this.salesForceItems.find(i => i.id === foundCostCenter.id_sales_force)
                                        let foundSalesForceBP = this.salesForceBPItems.find(i => i.id === foundCostCenter.id_sales_force_bp)

                                        this.selectedCostCenterItems.push({
                                            row: row,
                                            value: foundCostCenter.id
                                        })

                                        this.filteredData[row].id_product = foundCostCenter.id_product
                                        this.filteredData[row].id_cost_center = foundCostCenter.id
                                        this.filteredData[row].cd_cost_center = foundCostCenter.cd_cost_center
                                        this.filteredData[row].id_customer_group = foundCostCenter.id_customer_group
                                        this.filteredData[row].customer_group = foundCostCenter.customer_group
                                        this.filteredData[row].id_sales_channel = foundCostCenter.id_sales_channel
                                        this.filteredData[row].sales_channel = foundCostCenter.sales_channel
                                        this.filteredData[row].sales_force_name = foundSalesForce.text
                                        this.filteredData[row].id_sales_force = foundCostCenter.id_sales_force
                                        this.filteredData[row].sales_force_bp_name = foundSalesForceBP.text
                                        this.filteredData[row].id_sales_force_bp = foundCostCenter.id_sales_force_bp
                                    } else {
                                        let index = this.selectedCostCenterItems.findIndex(cd => cd.row === row)
                                        if (index > -1) {
                                            this.selectedCostCenterItems.splice(index, 1)
                                        }

                                        this.filteredData[row].product_name = null
                                        this.filteredData[row].cd_cost_center = null

                                        this.filteredData[row].id_cost_center = null
                                        this.filteredData[row].customer_group = null
                                        this.filteredData[row].id_customer_group = null
                                        this.filteredData[row].sales_channel = null
                                        this.filteredData[row].id_sales_channel = null
                                        this.filteredData[row].id_product = null
                                        this.filteredData[row].sales_force_name = null
                                        this.filteredData[row].id_sales_force = null
                                        this.filteredData[row].sales_force_bp_name = null
                                        this.filteredData[row].id_sales_force_bp = null
                                    }
                                }

                                if (!this.editedRows.includes(physicalRow)) {
                                    this.editedRows.push(physicalRow)
                                }

                                if (column.includes("value")) {
                                    this.hotInstance.setDataAtRowProp(row, column, newVal)
                                }

                                this.$nextTick(() => {
                                    this.hotInstance.loadData(this.filteredData)
                                })
                            }
                        })
                    }
                },
                language: ptBR.languageCode,
                fillHandle: {
                    autoInserRow: false
                },
                cells: (row, col, prop) => {
                    var cellProperties = {}

                    if (row === 0) {
                        cellProperties.className = ' total-column font-bold border-bottom-1'
                        cellProperties.readOnly = true
                        cellProperties.numericFormat = {
                            pattern: {
                                thousandSeparated: true,
                                negative: "parenthesis",
                                mantissa: 2,
                            },
                            culture: ptBR.languageCode
                        }
                    } else {
                        // let customerGroupId = this.filteredData[row]?.id_customer_group
                        // if (customerGroupId) {
                        //     let filteredCostCenters = this.setCostCenterSource(customerGroupId)
                        //     if (prop === 'cd_cost_center') {
                        //         cellProperties.source = filteredCostCenters.map(f => f.cd_cost_center)
                        //     }
                        // }

                        if (prop.includes('value') && prop !== 'value00' && !this.readOnlyColumns.includes(col)) {
                            let accumulatedAllocation = this.calculateAccumulatedAllocation(this.filteredData[row]?.id_employee, prop)

                            if (accumulatedAllocation === 100 || !accumulatedAllocation) {
                                cellProperties.className = this.removeAllocationColors(cellProperties.className)
                            } else if (accumulatedAllocation > 100) {
                                cellProperties.className += ' allocation-error-cell'
                            } else if (accumulatedAllocation < 100) {
                                cellProperties.className += ' allocation-warning-cell'
                            }
                        }
                    }

                    return cellProperties
                }
            },
            rowHeaders: [],
            stylizedRows: [],
            hiddenRows: { rows: [], indicators: true },
            hiddenColumns: { columns: [], copyPasteEnabled: true },
            contextMenu: [
                'copy',
                'hidden_rows_hide',
                'hidden_rows_show',
                'hidden_columns_hide',
                'hidden_columns_show',
            ],
            dropDownMenu: [
                'filter_by_condition',
                'filter_by_condition2',
                'filter_action_bar',
                'filter_operators',
                'filter_by_value'
            ],
            hotInstance: null,

            data: [],
            headers: [],
            columnsDialog: [],
            chartAccounts: null,
            selectedRows: null,
            editedRows: [],

            loading: true,
            exporting: false,
            importing: false,

            savedChanges: false,

            employeeRoles: [],

            selectedEmployeesItems: [],
            selectedCostCenterItems: [],
            selectedCustomerGroupItems: [],
            selectedRoleItems: [],
            selectedStatusItems: [],
            selectedHrClassificationItems: [],

            filteredColumns: [],
            filteredColHeaders: [],
            filteredData: [],
            deletedRows: [],

            formulaLength: null,
        }
    },

    computed: {
        disableDelete() {
            let disabled = true

            if (this.hotInstance) {
                if (this.hotInstance.getSelected()) {
                    let selection = this.hotInstance.getSelected()[0]

                    if (this.filteredData.length <= 1 || selection[0] === 0 || selection [2] === 0) {
                        return disabled
                    }

                    if (selection[1] === -1) {
                        disabled = false
                    }
                }
            }

            return disabled
        },

        disableSave() {
            let disabled = true

            if (this.editedRows.length || this.deletedRows.length) {
                disabled = false
            }

            return disabled
        }
    },  

    watch: {
        newEmployeeAllocationDialog: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.getData()
                }          
            }
        },
    },

    mounted() {
        registerLanguageDictionary(ptBR)
        numbro.registerLanguage(languages['pt-BR'])
    },

    updated() {
        if (this.$refs.hotTableNewEmployeeAllocation) {
            if (this.hotInstance === null) {
                this.hotInstance = this.$refs.hotTableNewEmployeeAllocation.hotInstance
                this.hotInstance.render()
            }
        }
    },

    methods: {
        calculateAccumulatedAllocation(employeeId, col) {
            let filteredEmployee = this.filteredData.filter(i => i.id_employee === employeeId)
            if (filteredEmployee.length) {
                return filteredEmployee.reduce((acc, cur) => {
                    return cur[col] ? acc + parseInt(cur[col] * 100, 10) : acc + 0
                }, 0)
            } else {
                return 0
            }
        },

        removeAllocationColors(className) {
            if (!className) {
                return
            }

            let classNames = className.split(' ')
            let errorIndex = classNames.findIndex(i => i.includes('allocation-error-cell'))
            if (errorIndex > -1) classNames.splice(errorIndex, 1)
            let warningIndex = classNames.findIndex(i => i.includes('allocation-warning-cell'))
            if (warningIndex > -1) classNames.splice(warningIndex, 1)

            return classNames.join(' ')
        },

        async getData() {
            this.loading = true
            this.filteredData = []
            this.hotInstance = null
            this.savedChanges = false

            this.filteredColumns = this.columns
            this.filteredColHeaders = this.colHeaders

            let filter = {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "input_planning",
                        operator: "=",
                        value: 1
                    }
                ]
            }

            try {
                const employeeRoleRes = await this.$http.post(this.$ipOrganization + 'employee/list-options', { filter })
                if (employeeRoleRes) {
                    this.employeeRoles = employeeRoleRes.data.rows

                    this.filteredColumns.forEach((c, index) => {
                        c['wordWrap'] = false
                        if (c.hide) {
                            this.hiddenColumns.columns.push(index)
                        }
    
                        if (c.data === 'employee_name') {
                            c.readOnly = false
                            c.type = 'dropdown'
                            c.source = this.employeeRoles.map(e => e.text)
                        }
    
                        // if (c.data === 'cd_cost_center') {
                        //     console.log({c})
                        //     c.readOnly = true
                        //     c.type = 'text'
                        //     // c.source = this.costCenterItems.map(e => e.cd_cost_center)
                        // }
                    })

                    if (this.selectedRowDuplicate) {
                        let employeeName = this.objectData[this.selectedRowDuplicate].employee_name
                        let filteredObjectData = this.objectData.filter(o => {
                            return o.employee_name === employeeName
                        })

                        this.filteredData = lodash.cloneDeep(filteredObjectData)
                    } else {
                        this.filteredData.push(this.generateEmptyRow())
                    }

                    this.filteredData.unshift(this.objectData[0])
                    
                    setTimeout(() => {
                        this.$nextTick(() => {
                            this.loading = false
                        })
                    }, 300)
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        setCostCenterSource(customerGroupId) {
            return this.costCenterItems.filter(c => c.id_customer_group === customerGroupId)
        },

        setProductSource(filteredCostCenters) {
            let productIds = filteredCostCenters.map(f => f.id_product)
            return this.productItems.filter(i => productIds.includes(i.id)).map(p => p.text)
        },

        generateEmptyRow() {
            let generatedNewRow = {}

            for (let key in this.objectData[0]) {
                generatedNewRow[key] = null
            }

            return generatedNewRow
        },

        insertNewRow() {
            this.filteredData.push(this.generateEmptyRow())

            this.$nextTick(() => {
                this.hotInstance.loadData(this.filteredData)
            })
        },

        delete(i) {
            if (this.filteredData[i].id_employee_allocation_planning) {
                this.deletedRows.push(this.filteredData[i])
            }
            let index = this.filteredData.findIndex(f => f === this.filteredData[i])
            this.editedRows = this.editedRows.filter(r => {
                return r !== index
            })
            this.editedRows = this.editedRows.map(r => {
                return index < r ? r - 1 : r
            })
            this.filteredData.splice(i, 1)
        },

        deleteSelectedRow() {
            if (this.selectedRows.length === 1) {
                let firstRow = this.selectedRows[0][0]
                let lastRow = this.selectedRows[0][2]

                if (firstRow < lastRow) {
                    for (let i = lastRow; i >= firstRow; i--) {
                        this.delete(i)
                    }
                } else if (firstRow > lastRow) {
                    for (let i = firstRow; i >= lastRow; i--) {
                        this.delete(i)
                    }
                } else if (firstRow === lastRow) {
                    let i = firstRow
                    this.delete(i)
                } else {
                    this.$toast.error('Erro inesperado')
                }
            } else {
                let rows = []

                this.selectedRows.forEach(r => {
                    rows.push({
                        firstRow: r[0],
                        lastRow: r[2]
                    })
                })

                rows.sort((a,b) => {
                    if (a.firstRow < b.firstRow) return 1
                    if (a.firstRow > b.firstRow) return -1
                    return 0
                })

                rows.forEach(r => {
                    if (r.firstRow < r.lastRow) {
                        for (let i = r.lastRow; i >= r.firstRow; i--) {
                            this.delete(i)
                        }
                    } else if (r.firstRow > r.lastRow) {
                        for (let i = r.firstRow; i >= r.lastRow; i--) {
                            this.delete(i)
                        }
                    } else if (r.firstRow === r.lastRow) {
                        let i = r.firstRow
                        this.delete(i)
                    } else {
                        this.$toast.error('Erro inesperado')
                    }
                })
            }

            if (!this.filteredData.length) {
                this.insertNewRow()
            } else {
                this.$nextTick(() => {
                    this.hotInstance.loadData(this.filteredData)
                })
            }
        },

        closeNewEmployeeAllocationDialog() {
            this.filteredData = []
            this.editedRows = []
            this.deletedRows = []
            this.hotInstance = null
            this.$emit('closeNewEmployeeAllocationDialog', this.savedChanges)
        },

        async save() {
            let payload = { items: [] }

            this.editedRows.forEach(r => {
                payload.items.push({
                    ...this.filteredData[r],
                    from_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                    exclude_from_ratio: r.exclude_from_ratio === 'Sim' ? 1 : 0,
                    input_planning: 1,
                    fiscal_year: this.event.fiscal_year,
                    id_event: this.event.id,
                    id_planning_group: this.selectedPlanningGroup ? this.selectedPlanningGroup : null
                })
            })

            if (this.deletedRows.length) {
                let deleteEmployees = { delete: { items: [] }}
                this.deletedRows.forEach(r => {
                    if (r.id_employee_allocation_planning) {
                        deleteEmployees.delete.items.push({ id_employee_allocation_planning: r.id_employee_allocation_planning })
                    }
                })

                try {
                    const deleteRes = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning' + '/mass-effect', { ...deleteEmployees })
                    if (deleteRes) {
                        this.savedChanges = true

                        if (payload.items.length) {
                            try {
                                const res = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning' + '/mass-save', { ...payload })
                                if (res) {
                                    this.$toast.success(res.data.msg)
                                    this.closeNewEmployeeAllocationDialog()
                                }
                            } catch (err) {
                                this.$fnError(err)
                            }
                        } else {
                            this.$toast.success(deleteRes.data.msg)
                            this.closeNewEmployeeAllocationDialog()
                        }
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            } else {
                try {
                    const res = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning' + '/mass-save', { ...payload })
                    if (res) {
                        this.savedChanges = true
                        this.$toast.success(res.data.msg)
                        this.closeNewEmployeeAllocationDialog()
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            }
        },

        async exportSheet() {
            this.exporting = true
            
            let payload = { items: [] }
            payload.items = this.filteredData.slice(1)
            for (let i = 0; i < payload.items.length; i++) {
                payload.items[i].id_event = this.event.id
            }

            try {
                const res = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning/report-employee', { ...payload} )
                if (res) {
                    window.open(res.data.token)
                    this.$toast.success(res.data.msg)
                    this.exporting = false
                }
            } catch (err) {
                this.exporting = false
                this.$fnError(err)
            }
        },

        importSheet(){
            this.$refs.uploader.click()
        },

        async onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)

                try {
                    const res = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning/upload-employee-spreadsheet',
                        body, { headers: { 'Content-Type': 'multipart/form-data' }}
                    )
                    if (res) {
                        // const massEffectRes = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning/mass-effect', { ...res.data.id, isSpreadsheetUpload: true})
                        // if (massEffectRes) {
                            this.$refs.uploader.value = null
                            this.$toast.success(res.data.msg)
                            this.importing = false
                            this.savedChanges = true
                            this.closeNewEmployeeAllocationDialog()
                        // }
                    }
                } catch (err) {
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                }
            }
        },
    }
}
</script>

<style scoped>
.v-btn--active::before, .v-btn:focus::before {
    opacity: 0 !important;
}
</style>

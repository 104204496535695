<template>
    <v-card style="position: relative;">
        <v-card-title class="light--text"
            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
            style="position: sticky; top: 0; width: 100%; z-index: 1000;"
        >
            <span ref="formTitle" class="headline">Novo funcionário</span>

            <v-spacer></v-spacer>

            <v-tooltip top>
                <template v-slot:activator="{on}">
                    <v-btn v-on="on"
                        class="pa-0 mr-2"
                        @click="returnEditedItemToDefault()"
                        min-width="48px"
                        text dark
                    >
                        <v-icon color="light">mdi-restart</v-icon>
                    </v-btn>
                </template>
                <span>Limpar campos</span>
            </v-tooltip>

            <v-tooltip top>
                <template v-slot:activator="{on}">
                    <v-btn v-on="on"
                        class="pa-0"
                        @click="close"
                        min-width="48px"
                        text dark
                    >
                        <v-icon color="light">mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>Fechar</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row v-if="loading" class="my-4">
                    <v-col class="d-flex justify-center align-center">
                        <v-sheet class="ma-0 pa-0 d-flex justify-center align-center">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                small
                            ></v-progress-circular>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-row v-else no-gutters>
                    <template v-for="(item, index) in editedItem" >
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="!item.hide" :key="item.name">
                            <v-text-field v-if="!item.options && (item.columnType === 'VARCHAR' || item.columnType === 'TEXT')"
                                v-model="item.value"
                                :label="item.nullColumn === 'NO' ? item.text + ' *' : item.text"
                                type="text"
                                :counter="item.length"
                                :maxlength="item.length"
                            />

                            <v-text-field v-else-if="!item.options && item.columnType === 'INT'"
                                v-model.number="item.value"
                                :label="item.nullColumn === 'NO' ? item.text + ' *' : item.text"
                                type="number"
                                :counter="item.length"
                                :maxlength="item.length"
                            />

                            <v-text-field v-else-if="!item.options && item.columnType === 'DECIMAL'"
                                v-model.number="item.value"
                                @change="formatFloat(item.value)"
                                :label="item.nullColumn === 'NO' ? item.text + ' *' : item.text"
                                type="number"
                                :counter="item.length"
                                :maxlength="item.length"
                            />

                            <v-autocomplete v-else-if="item.options && item.name.includes('country_code')"
                                :label="item.nullColumn === 'NO' ? item.text + ' *' : item.text"
                                v-model="item.value"
                                :items="item.options"
                                item-value="text"
                                :item-text="item => `${item.text} (${item.code})`"
                                return-object
                                @change="setSelectedOption(null, $event, item)"
                                :clearable="item.nullColumn === 'NO' ? true : false ? false : true"
                            />

                            <v-autocomplete v-else-if="item.options && !item.name.includes('country_code')"
                                :label="item.nullColumn === 'NO' ? item.text + ' *' : item.text"
                                v-model="item.value"
                                :items="getOptions(item)"
                                item-value="text"
                                return-object
                                @change="setSelectedOption(item.columnId, $event, item)"
                                :clearable="item.nullColumn === 'NO' ? true : false ? false : true"
                            />
                            
                            <v-menu v-else-if="item.columnType === 'DATE' || item.columnType === 'DATETIME'"
                                :ref="'menu' + index"
                                v-model="datepicker[index]"
                                :close-on-content-click="false"
                                :return-value.sync="item.value"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatDate(item.value)"
                                        :label="item.nullColumn === 'NO' ? item.text + ' *' : item.text"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :clearable="!item.nullColumn === 'NO' ? true : false"
                                        @click:clear="clearDatepicker(item.name, $event)"
                                    ></v-text-field>
                                </template>

                                <v-date-picker
                                    v-model="item.value"
                                    no-title
                                    scrollable
                                    color="primary"
                                >
                                    <v-spacer></v-spacer>
                                    
                                    <v-btn text color="primary" @click="saveSelectedDate(item.value, index)">
                                        Alterar
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </v-card-text>
        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
            <v-divider class="mx-4"></v-divider>
            <v-card-actions class="px-6 py-6">
                <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="close"
                    class="mr-4"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="save"
                    :loading="saving"
                    :disabled="disabled"
                >
                    Salvar
                </v-btn>
            </v-card-actions>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        newEmployeeDialog: { type: Boolean, required: true }
    },

    data() {
        return {
            editedItem: [],
            savedChanges: false,
            saving: false,
            loading: false,
            datepicker: [],

            options: [
                {
                    column: 'id_status',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Status',
                    required: false,
                    filterable: false,
                    endpoint: [ this.$ipUnit, 'status' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_status_type',
                            operator: '=',
                            value: 6
                        }
                    ],
                },
                {
                    column: 'id_role',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Cargo',
                    required: false,
                    filterable: false,
                    endpoint: [ this.$ipOrganization, 'role' ]
                },
                {
                    column: 'id_employee_type',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Tipo do funcionário',
                    required: false,
                    filterable: false,
                    endpoint: [ this.$ipOrganization, 'employee-type' ]
                },
                {
                    column: 'id_hr_classification',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Classificação do RH',
                    required: false,
                    filterable: false,
                    endpoint: [ this.$ipOrganization, 'hr-classification' ]
                },
                {
                    column: 'id_cost_center',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Centro de custo',
                    required: false,
                    filterable: false,
                    endpoint: [ this.$ipOrganization, 'cost-center' ]
                },
                {
                    column: 'id_user',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Usuário',
                    required: false,
                    filterable: false,
                    endpoint: [ this.$ipUser, 'user' ]
                },
                {
                    column: 'id_vendor',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Fornecedor',
                    required: false,
                    filterable: false,
                    endpoint: [ this.$ipVendor, 'vendor' ]
                },
                {
                    column: 'id_product',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Produto',
                    required: false,
                    filterable: false,
                    endpoint: [ this.$ipProduct, 'product' ]
                },
            ],
        }
    },

    computed: {
        disabled() {
            let disabled = false

            this.editedItem.forEach(i => {
                if (i.nullColumn === 'NO' && !i.hide && !i.value) {
                    disabled = true
                }
            })

            return disabled
        },
    },

    watch: {
        newEmployeeDialog: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.load()
                }          
            }
        },
    },

    beforeMount() {
        this.savedChanges = false
        
        this.options.forEach(async o => {
            let filter = {}

            if (o.conditions) {
                filter = {
                    conditions: [ ...o.conditions ]
                }
            }

            try {
                let res = await this.$http.post(o.endpoint[0] + o.endpoint[1] + '/list-options', { filter })
                if (res) {
                    o.items = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        })
    },

    methods: {
        returnEditedItemToDefault() {
            this.editedItem.forEach(i => {
                i.value = null
            })
        },

        async load() {
            this.loading = true
            this.savedChanges = false

            try {
                const res = await this.$http.post(this.$ipOrganization + 'employee/list', {})
                if (res) {
                    let headers = res.data.headers
                    let columns = res.data.columns

                    headers.forEach(h => {
                        if (h.value !== 'input_planning') {
                            let foundColumn = columns.find(c => c.columnAlias === h.value)

                            if (foundColumn.columnAlias === 'user_name' || foundColumn.columnAlias === 'vendor_description' || foundColumn.columnAlias === 'product_name') {
                                foundColumn.nullColumn = 'YES'
                            }
                            
                            this.editedItem.push(
                                {
                                    ...h,
                                    ...foundColumn,
                                    hide: foundColumn.key === 'MUL' || foundColumn.key === 'PRI' ? true : false,
                                    name: h.value,
                                    value: null
                                }
                            )
                        }
                    })

                    this.loading = false
                }
            } catch (err) {
                this.loading = false
                this.$fnError(err)
            }
        },

        getOptions(item) {
            if (item.options.length) {
                return item.options
            } else {
                let option = this.options.find(option => option.column === item.columnId)
                if (!option) console.log({item})
                return option.items
            }
        },      

        setSelectedOption(columnId, e, item) {
            if (item.name.includes('country_code')) {
                let index = this.editedItem.findIndex(i => i.name === item.name)
                let value = null

                if (e) {
                    value = e.text.slice(0, 2)
                }

                this.editedItem[index].value = value
            } else {
                this.editedItem.forEach((item, i) => {
                    if (e) {
                        if (item.name === columnId) this.editedItem[i].value = e.id
                    } else {
                        if (item.name === columnId) this.editedItem[i].value = null
                    }
                })
            }
        },

        requiredValidation(item) {
            if (item && item.value !== null) {
                if ( item.value.length === 0 ) return true
            }
        },

        clearDatepicker(name, e) {
            this.editedItem.forEach((item, i) => {
                if (e) {
                    if (item.name === name) this.editedItem[i].value = e.id
                } else {
                    if (item.name === name) this.editedItem[i].value = null
                }
            })
        },

        formatDate(date) {
            if (date === undefined || date === null) {
                return ''
            } else {
                return this.$options.filters.dateFilter(date)
            }
        },

        saveSelectedDate(date, index) {
            this.$refs['menu' + index][0].save(date)
            this.date = this.$options.filters.dateFilter(date)
        },

        close() {
            this.editedItem = []
            this.$emit('closeNewEmployeeDialog', this.savedChanges)
        },

        async save() {
            this.saving = true
            let payload = {}

            this.editedItem.forEach(i => {
                payload[i.name] = i.value
            })

            payload['input_planning'] = 1

            try {
                const res = this.$http.post(this.$ipOrganization + 'employee/create', { ...payload })
                if (res) {
                    this.savedChanges = true
                    this.close()
                    this.saving = false
                    this.$toast.success('Funcionário registrado com sucesso!')
                }
            } catch (err) {
                this.$fnError(err)
                this.saving = false
            }
        },

        setSwitchValue(e, item) {
            if (e) {
                item.value = 1
            } else {
                item.value = 0
            }
        },
    }
}
</script>

<style>

</style>